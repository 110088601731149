<template>
  <section id="content" style="margin-bottom: 80px ">
    <v-card
      class="mx-auto py-1 mb-2"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="mx-auto py-0 py-md-6">
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            Consulting
          </v-card-title>
          <v-card-text
            class="text-left text-center subtitle-1 font-weight-light mx-auto mb-5"
          >
            <b> Our experts can help with: </b><br />
            • Advice on suitable plants for any size garden<br />
            • Advice on water wise gardening.<br />
            • Identification and removal of alien species<br />
            • Advice on landscaping projects.
          </v-card-text>
        </v-container>
      </v-sheet>

      <v-card-text class=" title text-center">
        Meet the team:
      </v-card-text>

      <v-row>
        <v-card
          :loading="loading"
          class="mx-auto my-2"
          width="40%"
          max-width="550"
        >
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>
          <v-hover>
            <template v-slot="{ hover }">
              <v-img
                :height="$vuetify.breakpoint.mdAndUp ? 300 : 100"
                src="https://img.icons8.com/color/200/000000/person-female.png"
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-6"
                  color="secondary"
                  opacity=".9"
                >
                  <base-subheading class="subtitle-2">
                    Zan
                  </base-subheading>
                  <br /><br />
                  <base-body>
                    A specialist in indigenous landscaping projects with years
                    of experience in the KZN area. Zan can make any garden come
                    to life.
                  </base-body>
                </v-overlay>
              </v-img>
            </template>
          </v-hover>

          <v-card-title>Zan Bowker</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <a
                href="tel:+27-83-447-1503"
                target="_blank"
                rel="noopener"
                color="thirdley"
                style="text-decoration: none;"
                tile
                outlined
              >
                <v-icon v-text="'mdi-phone'" />
                083 447 1503 &nbsp;&nbsp;
              </a>
            </v-row>

            <div class="my-4 subtitle-1">
              <a
                href="mailto:info@twinstreams.co.za"
                target="_blank"
                rel="noopener"
                color="thirdley"
                style="text-decoration: none;"
                tile
                outlined
              >
                <v-icon v-text="'mdi-email'" /> info@twinstreams.co.za
              </a>
            </div>
            <v-divider class="mx-4" />
            <br />
            <div>
              I specialize in: <br />
              <i small class="fas fa-seedling" /> consulting for garden design,
              specific to aspect, plant preferences ( shade or sun)
              <br />
              <i small class="fas fa-seedling" /> small garden species ,
              effective small gardens, patio gardens, potted gardens, roof
              gardens
              <br />
              <i small class="fas fa-seedling" /> Maintenance plans , including
              dealing with disease , pests and fungi
              <br />
              <i small class="fas fa-seedling" /> Internal / external pot
              gardens <br />
              <i small class="fas fa-seedling" /> Regular garden design and
              installation gardens <br />
              <i small class="fas fa-seedling" /> Fertilization programs for
              planters, garden beds and lawns gardens <br />
              <i small class="fas fa-seedling" />complete landscape
              installations, from start to finish with own staff and all
              materials supplied gardens <br />
              <i small class="fas fa-seedling" /> complete costings and quotes
              for any landscape requirements <br />
              <i small class="fas fa-seedling" /> I also offer once of
              consultations <br />
            </div>
          </v-card-text>

          <v-divider class="mx-4" />

          <!-- <v-card-title class="subtitle-1"
            >I have always been passionate about indigenous plants. Feel free to
            email any querys about etc.
          </v-card-title> -->

          <v-card-actions>
            <v-btn
              color="deep-purple lighten-2"
              text
              href="mailto:info@twinstreams.co.za"
            >
              Email
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card :loading="loading" class="mx-auto mrn-9 my-12" max-width="374">
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>
        </v-card>

        <!--person 2 -->

        <v-card
          :loading="loading"
          class="mx-auto my-2"
          width="40%"
          max-width="550"
        >
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>
          <v-hover>
            <template v-slot="{ hover }">
              <v-img
                :height="$vuetify.breakpoint.mdAndUp ? 300 : 100"
                src="https://img.icons8.com/color/188/000000/person-female.png"
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-6"
                  color="secondary"
                  opacity=".9"
                >
                  <base-subheading class="subtitle-2">
                    Michele
                  </base-subheading>
                  <br /><br />
                  <base-body>
                    As a SA trained botanist Michele will be able to help with a
                    wide array of services for all areas of SA with specific
                    interest in KZN, Mpumalanga and Limpopo.
                  </base-body>
                </v-overlay>
              </v-img>
            </template>
          </v-hover>

          <v-card-title>Michele Hofmeyr</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <a
                href="tel:+27-74-189-6354"
                target="_blank"
                rel="noopener"
                color="thirdley"
                style="text-decoration: none;"
                tile
                outlined
              >
                <v-icon v-text="'mdi-phone'" />
                074 189 6354 &nbsp;&nbsp;
              </a>
            </v-row>

            <div class="my-4 subtitle-1">
              <a
                href="mailto:info@twinstreams.co.za"
                target="_blank"
                rel="noopener"
                color="thirdley"
                style="text-decoration: none;"
                tile
                outlined
              >
                <v-icon v-text="'mdi-email'" /> info@twinstreams.co.za
              </a>
            </div>
            <v-divider class="mx-4" />
            <br />
            <div>
              I specialize in: <br />
              <i small class="fab fa-pagelines" /> Identification of indigenous
              flowers, shrubs and trees - to compile species lists for general
              information, to compile tree walks around estates, lodges or camps
              or to add to additional species vegetation lists in management
              plans.
              <br />
              <i small class="fab fa-pagelines" /> Tree labelling service -
              trees and shrubs with local names and tree info if required.
              <br />
              <i small class="fab fa-pagelines" /> Information on indigenous
              plants - specifically on conservation status and medicinal or
              cultural uses for general information for guests, to compile tree
              walks or for guide training.
              <br />
              <i small class="fab fa-pagelines" />Assistance in compiling plant
              lists per area for rehabilitation programmes, indigenous waterwise
              plants for gardens, trees for community greening or outreach
              programmes, plants less palatable to herbivores including
              elephants and trees suitable for planting near infrastructure or
              for shade
              <br />
              <i small class="fab fa-pagelines" /> Identification of alien and
              invasive plant species, recommendations for eradication of alien
              plants and suitable indigenous alternatives. This will be in line
              with the CARA legislation (CONSERVATION OF AGRICULTURAL RESOURCES
              ACT, 1983) for alien invasive species and also the NEMBA
              legislation including the updated alien species lists which are
              available on the
              <a
                href=" https://www.environment.gov.za/legislation/actsregulations "
              >
                DEA website.
              </a>
            </div>
          </v-card-text>

          <v-divider class="mx-4" />

          <!--  <v-card-title class="subtitle-1"
            >I have always been passionate about indigenous plants. Feel free to
            email any querys about etc.
          </v-card-title>  -->

          <v-card-actions>
            <v-btn
              color="deep-purple lighten-2"
              text
              href="mailto:info@twinstreams.co.za"
            >
              Email
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>
        </v-card>

        <!--person 3 -->
        <!-- <v-card :loading="loading" class="mx-auto my-12" max-width="374">
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>
          <v-hover>
            <template v-slot="{ hover }">
              <v-img
                :height="$vuetify.breakpoint.mdAndUp ? 300 : 100"
                :src="require('@/assets/Main/consulting/person3.jpg')"
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-6"
                  color="secondary"
                  opacity=".9"
                >
                  <base-subheading class="subtitle-2">Michele</base-subheading>
                  <br /><br />
                  <base-body>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Unde recusandae provident, accusantium suscipit nesciunt
                    dignissimos architecto saepe. Aspernatur, consequatur
                    architecto. Fugit ex culpa adipisci in at voluptatem,
                    laboriosam enim doloribus?
                  </base-body>
                </v-overlay>
              </v-img>
            </template>
          </v-hover>

          <v-card-title>person #3</v-card-title>

          <v-card-text>
            <v-row align="center" class="mx-0">
              <a
                href="tel:+27-74-189-6354"
                target="_blank"
                rel="noopener"
                color="thirdley"
                style="text-decoration: none;"
                tile
                outlined
              >
                <v-icon v-text="'mdi-phone'" />
                074 189 6354 &nbsp;&nbsp;
              </a>
            </v-row>

            <div class="my-4 subtitle-1">
              <a
                href="mailto:info@twinstreams.co.za"
                target="_blank"
                rel="noopener"
                color="thirdley"
                style="text-decoration: none;"
                tile
                outlined
              >
                <v-icon v-text="'mdi-email'" /> info@twinstreams.co.za
              </a>
            </div>
            <v-divider class="mx-4" />
            <br />
            <div>
              I specialize in: <br />
              <i small class="fab fa-leaf"></i> Identification of
              indigenous flowers, shrubs and trees - to compile species lists
              for general information, to compile tree walks around estates,
              lodges or camps or to add to additional species vegetation lists
              in management plans.
              <br />
              <i small class="fab fa-leaf"></i> Tree labelling service -
              trees and shrubs with local names and tree info if required.
              <br />
              <i small class="fab fa-leaf"></i>• Information on indigenous
              plants - specifically on conservation status and medicinal or
              cultural uses for general information for guests, to compile tree
              walks or for guide training.
              <br />
              <i small class="fab fa-leaf"></i> • Identification of alien
              and invasive plant species, recommendations for eradication of
              alien plants and suitable indigenous alternatives. This will be in
              line with the CARA legislation (CONSERVATION OF AGRICULTURAL
              RESOURCES ACT, 1983) for alien invasive species and also the NEMBA
              legislation including the updated alien species lists which are
              available on the DEA website
              https://www.environment.gov.za/legislation/actsregulations
            </div>
          </v-card-text>

          <v-divider class="mx-4" />

          <v-card-title class="subtitle-1"
            >I have always been passionate about indigenous plants. Feel free to
            email any querys about grafting and mulching. Good with pesticide.
          </v-card-title>

          <v-card-actions>
            <v-btn color="deep-purple lighten-2" text @click="reserve">
              Email
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card :loading="loading" class="mx-auto my-12" max-width="374">
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate />
          </template>
        </v-card> -->
      </v-row>
      <v-sheet class="mx-auto py-0 mb-2" color="grey lighten-2">
        <p style="text-align: center;">
          <strong>Open</strong>
          <br />
          <em>Monday:</em> closed
          <br />
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br />
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br />
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "SampleContents",
  data: () => ({
    items: [
      {
        src: "/static/blockhouse/Carousal1.png"
      },
      {
        src: "/static/blockhouse/Carousal2.png"
      },
      {
        src: "/static/blockhouse/Carousal3.png"
      },
      {
        src: "/static/blockhouse/Carousal4.png"
      },
      {
        src: "/static/blockhouse/Carousal5.png"
      },
      {
        src: "/static/blockhouse/Carousal6.png"
      },
      {
        src: "/static/blockhouse/Carousal7.png"
      },
      {
        src: "/static/blockhouse/Carousal8.png"
      },
      {
        src: "/static/blockhouse/Carousal9.png"
      },
      {
        src: "/static/blockhouse/Carousal10.png"
      },
      {
        src: "/static/blockhouse/Carousal11.png"
      }
    ],
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],
    socials: [
      ["https://www.facebook.com/bikeparkuitsig/", "mdi-facebook", "Facebook"],
      [
        "https://www.instagram.com/bikepark_uitsig/",
        "mdi-instagram",
        "Instagram"
      ],
      ["https://bikeparkatuitsig.co.za/", "mdi-web", "Website"]
    ]
  })
};
</script>

<style scoped>
.v-card {
  border-radius: 0px;
}
</style>
